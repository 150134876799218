import React, { useState } from "react";
import { motion } from "framer-motion";

const InteractiveNoDataPage = () => {
  const [data, setData] = useState([]); // Empty array to simulate no data

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 px-4">
      {data.length === 0 ? (
        // No Data Available State
        <motion.div
          className="flex flex-col items-center"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
        >
          {/* Animated SVG */}
          <motion.div
            className="mb-6 text-gray-400"
            initial={{ y: -50 }}
            animate={{ y: 0 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-32 w-32"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.75 9.75L14.25 14.25M14.25 9.75L9.75 14.25M21 12C21 16.4183 16.9706 20 12 20C7.02944 20 3 16.4183 3 12C3 7.58172 7.02944 4 12 4C16.9706 4 21 7.58172 21 12Z"
              />
            </svg>
          </motion.div>

          {/* Animated Text */}
          <motion.h1
            className="text-3xl font-bold text-gray-600"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.3 }}
          >
            No Data Found
          </motion.h1>

          <motion.p
            className="text-gray-500 mt-2"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.5 }}
          >
            Try refreshing or check back later.
          </motion.p>

          {/* Refresh Button */}
          <motion.button
            className="mt-6 px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => {
              alert("No data available yet!");
            }}
          >
            Refresh
          </motion.button>
        </motion.div>
      ) : (
        // Data Available State
        <div className="flex flex-col items-center">
          <h1 className="text-3xl font-bold text-gray-600 mb-4">Data Loaded</h1>
          <ul className="bg-white shadow-md rounded-lg p-6 w-full max-w-md">
            {data.map((item, index) => (
              <li
                key={index}
                className="border-b last:border-none py-2 px-4 text-gray-700"
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default InteractiveNoDataPage;
import React from 'react';
import { motion } from 'framer-motion';
import picture12 from '../assets/picture12.jpg';
import icon1 from '../assets/icon1.png';
import icon2 from '../assets/icon2.png';
import icon3 from '../assets/icon3.png';

const Services = () => {
  return (
    <div className='h-auto font-roboto sm:m-0 sm:mt-0 overflow-x-hidden'>
      {/* Main Header Section */}
      <div className='sm:grid mt-[150px] sm:mt-[100px] grid-cols-2 w-full h-[50vh]'>
        <div className='sm:ml-[50px] leading-[11vh] sm:leading-[18vh]'>
          <motion.div
            initial={{ x: -100 }}
            animate={{ x: 20 }}
            transition={{ duration: 1 }}
            className='font-[Mona Sans] mt-10 mb-3'
          >
            <div>
              <p className='text-[5vh] sm:text-[11vh]'>OurCompany</p>
            </div>
            <p className='text-[5vh] sm:text-[15vh] -mt-[50px]'>Services!</p>
          </motion.div>
          <motion.div className='sm:w-[40vw] ml-[20px] sm:ml-[30px]'>
            <p className='font-[Neue Montreal] mb-[20px] text-[2.2vh] leading-6 sm:font-semibold'>
              Welcome to Jayz Energy! We are your trusted partners in Design, Engineering, and Project Management. From Basic Engineering Packages to Front-End Engineering Design, from 2D and 3D Modeling Services to Plant Layout Engineering, and from Project Cost Estimation to Vendor Identification, we have got you covered.&nbsp;
            </p>
          </motion.div>
        </div>
        <div className='relative mt-[70px] mr-[10px] w-full sm:w-[45vw] '>
          <img
            className='h-[40vh] w-full sm:w-[45vw] sm:rounded-[100px] object-cover'
            src={picture12}
            alt='no render'
          />
        </div>
      </div>

      {/* Services Section */}
      <div className='mt-[350px] mb-[80px] sm:grid grid-cols-3 sm:mt-[100px] sm:pl-[70px]'>
        {/* Plant Engineering */}
        <motion.div
          whileHover={{ scale: 1.1 }}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 1 }}
          className='border border-black sm:rounded-[100px] sm:w-[25vw] mb-3 sm:mb-0 h-auto'
        >
          <div className='m-[7vw] sm:m-[2vw]'>
            <img className='sm:w-[3vw] ml-[2vw]' src={icon1} alt='no render' />
            <h1 className='text-xl font-semibold mt-[1vw]'>Plant Engineering</h1>
            <h4 className='sm:text-[1vw] mt-[1vw]'>
              Our core area of expertise is in the development of Basic Engineering Packages (BEP) and Front-End Engineering Design (FEED)
            </h4>
            <ul className='list-disc sm:text-[1vw] ml-[2vw] mt-[1vw] font-bold'>
              {[
                'Concept Design',
                'Front End Engineering Design',
                'HAZOP Study & SIL Analysis',
                'Detail Design',
                '3D Modeling',
                'Cost Engineering',
                'Procurement Support',
                'Piping Stress Analysis',
              ].map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </motion.div>

        {/* Drafting Services */}
        <motion.div
          whileHover={{ scale: 1.1 }}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 1 }}
          className='border border-black sm:rounded-[100px] sm:w-[25vw] mb-3 sm:mb-0 h-auto'
        >
          <div className='m-[7vw] sm:m-[2vw]'>
            <img className='sm:w-[3vw] ml-[2vw]' src={icon2} alt='no render' />
            <h1 className='sm:text-xl font-semibold mt-[1vw]'>Drafting Services</h1>
            <h4 className='sm:text-[1vw] mt-[1vw]'>
              2D & 3D Modeling Services related to Plant Layout Engineering
            </h4>
            <ul className='list-disc sm:text-[1vw] ml-[2vw] mt-[1vw] font-bold'>
              {[
                'Manufacturing Drawings',
                'As-Built Drawings',
                'All types of 2D Drawings Conversion',
              ].map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </motion.div>

        {/* Project Engineering & Management */}
        <motion.div
          whileHover={{ scale: 1.1 }}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 1 }}
          className='border border-black sm:rounded-[100px] sm:w-[25vw] h-auto'
        >
          <div className='m-[7vw] sm:m-[2vw]'>
            <img className='sm:w-[3vw] ml-[2vw]' src={icon3} alt='no render' />
            <h1 className='sm:text-xl font-semibold mt-[1vw]'>
              Project Engineering & Management Services
            </h1>
            <h4 className='sm:text-[1vw] mt-[1vw]'>
              Our experienced professionals offer project management services ranging from bid documents, vendor sourcing, quality management, inspection, and many more.
            </h4>
            <ul className='list-disc sm:text-[1vw] ml-[2vw] mt-[1vw] font-bold'>
              {['Project Management', 'Document Management'].map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Services;